.cookie-banner-wrapper {
  padding: 24px 60px !important;
  background-color: white !important;
  color: black !important;
  position: relative !important;
  font-family: 'Satoshi' !important;
  font-size: 21px;
  font-style: normal;
  font-weight: 400;
  line-height: 110%;
  box-sizing: border-box;
  width: 100%;
  margin: auto;
  max-width: 1920px;
  height: auto;
  display: flex;
  align-items: center !important;
  gap: 40px;
}
.button-wrapper {
  display: flex;
  flex-direction: row-reverse;
  gap: 12px;
}
.cookie-content {
  margin: 0 !important;
  display: flex;
  align-items: center;
}
.cookie-image {
  margin-right: 12px;
  height: 66px;
  width: 66px;
}

@media only screen and (max-width: 1024px) {
  .cookie-image {
    margin-right: 0;
    margin-bottom: 12px;
  }
  .cookie-banner-wrapper {
    flex-direction: column;
    flex-basis: 100%;
    width: 100%;
    bottom: 24px;
    padding: 24px 18px !important;
    gap:20px;
  }
  .cookie-content {
    flex: 1 0 60px !important;
    flex-direction: column;
  }
  .button-wrapper {
    flex-direction: column;
  }
  .button-wrapper > button {
    width: calc(100vw - 2* 18px) !important;
  }
}