@font-face {
  font-family: 'Satoshi';
  src: url('./fonts/Satoshi-Variable.woff2') format('woff2'),
        url('./fonts/Satoshi-Variable.woff') format('woff'),
        url('./fonts/Satoshi-Variable.ttf') format('truetype');
        font-weight: 300 900;
        font-display: swap;
        font-style: normal;
  }
@font-face {
      font-family: 'Satoshi Italic';
      src: url('./fonts/Satoshi-Variable.woff2') format('woff2'),
            url('./fonts/Satoshi-Variable.woff') format('woff'),
            url('./fonts/Satoshi-Variable.ttf') format('truetype');
      font-weight: 300 900;
      font-display: swap;
      font-style: italic;
}