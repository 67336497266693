body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden !important;
  position: relative;
  scroll-behavior: smooth !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
* {
  box-sizing: border-box;
}
button {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  outline: none;
  border: none;
  background: none;
  padding: 0;
  margin: 0;
  font: inherit;
  color: inherit;
  text-align: inherit;
  text-decoration: none;
  cursor: inherit;
  cursor: pointer;
}


#contact > ::selection {
  color: rgb(30, 30, 30) !important;
  background: white !important;
}
a:link,
a:visited {
  color: inherit;
  text-decoration: none;
  background-color: transparent;
  cursor: pointer;
  border: inherit;
}
textarea,
input[type="text"],
input[type="button"],
input[type="submit"], 
input[type="checkbox"]
input[type="email"] {
  -webkit-appearance: none;
  appearance: none;
  border-radius: 0 !important;
  width: inherit !important;
}

#pp {
  font-size: 21px !important;
}

.logo {
  width: 100%;
  height: auto;
  aspect-ratio: auto;
}